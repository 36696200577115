.button
  display: inline-flex
  justify-content: center
  align-items: center
  height: 40px
  padding: 0 24px
  color: $dark
  font-size: .95em
  font-weight: 500
  border-radius: 12px
  transition: all ease-out .25s
  background-color: $white
  outline: none !important
  &.mob-button
    +v-xs
      display: none
  &.mob-button-show
    display: none
    +v-xs
      display: flex
      margin-top: 16px !important
      max-width: 220px !important
      max-height: 48px !important
  +v-sm
    min-width: 224px
  &.mini
    height: 40px
    padding: 0 20px
    font-weight: 500
    color: $blue !important
    background-color: $white
    border: 2px solid $blue
    +v-sm
      width: 90% !important
      margin-left: 5% !important
      margin-right: 5% !important
      margin-bottom: 8px
  &.blue
    color: $white !important
    background-color: $blue
    font-weight: 400
    height: 48px
    padding: 0 32px
    +v-sm
      display: flex
      width: 240px
      text-align: center
      margin-left: auto !important
      margin-right: auto !important
  &.dark
    color: $white !important
    background-color: $dark
    height: 48px
    padding: 0 32px
  &.outline
    height: 48px
    padding: 0 32px
    font-weight: 500
    color: $blue !important
    border: 2px solid $blue
    &.int
      margin-left: 16px !important
      +v-sm
        margin-left: auto !important
        margin-right: auto !important
    &.cookies
      min-width: 220px
      margin-left: auto !important
      margin-right: auto
