html
  font-family: $basic
  font-weight: normal
  font-size: 1rem
  font-display: auto

body
  width: 100%
  max-width: 100% !important
  padding-top: 80px
  color: $dark
  font-size: 95%
  font-family: $basic
  font-weight: 400
  font-smooth: always
  letter-spacing: .03em
  line-height: 1.6
  -webkit-font-smoothing: antialised
  -moz-osx-font-smoothing: grayscale
  overflow-x: hidden
  background-color: $body
  &.menu-active
    height: 100vh
    overflow: hidden

.mob-button
  +v-sm
    position: fixed
    right: 80px
    top: 16px

a, * a
  color: inherit !important
  text-decoration: none !important

.col-lg-*
  +v-sm
    margin-top: 10px
    margin-bottom: 10px

#results,
#about,
#donations
  *
    +v-sm
      text-align: center !important

#top
  +v-sm
    *
      text-align: center !important
  img
    display: block
    width: 100%
    height: auto
    object-fit: cover
    border-radius: 40px

.card
  &-res
    width: 100%
    padding: 32px 24px
    background-color: #F4F8FB
    border-radius: 16px
    box-shadow: 0 3px 2px rgba(0,0,0,.07)
    &.active
      box-shadow: none
      background-color: #fff !important
      border: 3px solid $lite !important
    &.how
      padding: 40px 64px
      height: auto
      min-height: 330px
      background: $lite
      box-shadow: none
      border: 3px solid $lite !important
      +v-sm
        padding: 40px
        .w-5.d-inline-block
          display: block !important
          clear: both
        *
          text-align: center !important
    &.tab
      height: 99px
      padding: 0 24px
      +v-sm
        height: auto !important
        padding: 24px !important
    &.bg
      &-1
        background-color: $bg1
        box-shadow: 0 3px 2px darken($bg1,7%)
      &-2
        background-color: $bg2
        box-shadow: 0 3px 2px darken($bg2,1%)
      &-3
        background-color: $bg3
        box-shadow: 0 3px 2px darken($bg3,7%)
      &-4
        background-color: darken($bg2,2%)
        box-shadow: 0 3px 2px darken($bg2,3%)
    &.noactive
      padding: 24px 32px
      box-shadow: none
      background-color: $lite
      &:hover
        box-shadow: 0 8px 28px -6px rgba(24, 39, 75, .12), 0 18px 88px -4px rgba(24, 39, 75, .14)

.list
  margin: 0
  padding: 0
  list-style: none
  border-bottom: 1px dashed $light
  p
    padding: 0 !important
    margin: 0 !important

#veterans,
#veterans
  span.title-mini
    display: block
    padding: 0 !important
    margin: -4px 16px !important
  .flag
    border-radius: 8px
    box-shadow: 0 0 2px $light
  .tl
    +v-sm
      margin-top: 12px
      text-align: center !important

.laptop
  background: $lite url('../../assets/laptop.jpg') center center no-repeat
  background-blend-mode: multiply
  background-size: cover

#form
  *
    font-family: 'Rubik', sans-serif !important
    box-shadow: none !important
    border-color: transparent !important
    font-size: 1em !important
    color: $medium !important
  +v-xs
    .px-5
      padding-left: 24px !important
      padding-right: 24px !important
    .m-5
      margin: 0 !important

#faq
  +v-xs
    .px-5
      padding-left: 8px !important
      padding-right: 8px !important

.accordion
  margin: 0 -30px !important
  padding: 0 !important
  background-color: transparent !important
  &-button
    color: $metal !important
    font-weight: 500 !important
    font-size: .6em
    outline: none !important
    box-shadow: none !important
    &:not(.collapsed)
      color: lighten($metal,10%) !important
      font-weight: 500 !important
      background-color: transparent !important
  *
    border-color: transparent !important
    border-width: 0 !important
    background-color: transparent !important
  &-body
    background-color: transparent !important

.accordion-header + .accordion .collapse.show
  background-color: $white !important

.line
  width: 100%
  height: 1px
  margin-bottom: 12px !important
  background-color: darken($lite,3%)

footer
  border-top: 2px solid $lite
  +v-sm
    display: block
    padding: 40px 20px !important
    height: auto
    min-height: auto !important
    text-align: center !important
  img
    transition: all ease-out .3s
    &:hover
      opacity: .5

img
  +v-sm
    width: auto !important
    max-width: 80% !important
    margin-left: auto !important
    margin-right: auto !important

.m-30
  display: none
  +v-sm
    display: block
    width: 100%
    height: 30px
.m-20
  display: none
  +v-sm
    display: block
    width: 100%
    height: 20px

.title
  &-sub
    +v-sm
      padding-top: 20px
      font-size: 1.65em !important
      margin-left: auto
      margin-right: auto
      text-align: center
    +v-xs
      padding-top: 0
  &-intro
    &-sub
      +v-sm
        font-size: 1.25em
        max-width: 80% !important
        margin-left: auto
        margin-right: auto

#stories
  .title
    +v-xs
      font-size: 1.25em
    &-intro
      +v-sm
        padding-top: 40px
        text-align: center
      &-sub
        +v-sm
          text-align: center
  .px-5
    +v-xs
      padding-left: 0 !important
      padding-right: 0 !important

#contact
  a
    +v-xs
      display: block
      min-width: 100%
      text-align: center
      margin-bottom: 10px
    &.c-medium
      +v-xs
        display: inline-flex
        min-width: auto
        text-align: inherit
        margin-bottom: 0

#lists
  .mob-show
    display: none
    +v-sm
      display: block
  .mob-hide
    display: block
    +v-sm
      display: none
  .pics
    +v-sm
      height: 220px !important
      object-fit: cover
  .s-30
    +v-sm
      display: none !important
  .s-100
    +v-sm
      display: none !important
  #veterans
    > .col-lg-6
      +v-sm
        order: 1 !important
        text-align: center !important
      img
        +v-sm
          margin-left: auto !important
          margin-right: auto !important
  > .col-lg-6.p-5
    +v-sm
      order: 2 !important
      width: 100% !important
      min-width: 100% !important
      margin-left: auto !important
      margin-right: auto !important
      padding-left: 0 !important
      padding-left: 0 !important
  .list
    *
      +v-xs
        font-size: .92em !important
    +v-sm
      margin-left: auto !important
      margin-right: auto !important
      height: auto !important
      padding-top: 8px !important
      padding-bottom: 8px !important
  .w-70.flex.aic
    +v-sm
      min-width: 96% !important
      margin-left: 2% !important
      margin-right: 2% !important
      display: flex !important
    img
      +v-sm
        width: 32px !important
        margin-left: 16px !important
    .h-10.pt-2.px-3
      +v-sm
        width: calc(100% - 40px) !important
        padding-left: 12px !important
        padding-right: 0 !important
