.b-none
  border: none !important

// Background Colors
.bg
  &-metal
    background-color: $metal !important
  &-body
    background-color: $body !important
  &-white
    background-color: $white !important
  &-dark
    background-color: $dark !important
  &-blue
    background-color: $blue !important
  &-marine
    background-color: $marine !important
  &-light
    background-color: $light !important
  &-lite
    background-color: $lite !important
  &-theme
    background-color: $theme !important
  &-grey
    background-color: $grey !important
