@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap')

$body: #fff
$theme: #D3B1DF
$dark: #272C36
$white: #fff
$light: #C6C6C6
$medium: #475883
$lite: #fafafa

$bg1: #F1FFD3
$bg2: #F2F6FF
$bg3: #FFF7CA

$button: $theme

$blue: #0057B7
$violet: #8E4A95
$darkblue: #3f436c
$pink: #EA4E98
$marine: #00AACB
$footer: #44727B
$grey: #cdcdcd
$green: #99b660

$metal: #484D58

$basic: 'Rubik', sans-serif
$intro: 'Rubik', sans-serif

=placeholder
  &::-webkit-input-placeholder
    @content
  &:-moz-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content
