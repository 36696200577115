.title
  margin: 0
  padding: 0
  color: $light
  font-size: 1.5em
  font-weight: 600
  &-icon
    font-size: 2em
    &.first
      font-size: 1.42em
    &-1
      font-size: 2.22em
    &-2
      font-size: 1.72em
    &-3
      font-size: 2.22em
  +v-sm
    margin: 0 0 16px 8px
  &-mini
    font-size: 0.85em
  &-cta
    font-size: 2.25em
    font-weight: 400
    line-height: 1.6
    +v-sm
      font-size: 1.65em
      font-weight: 400
      line-height: 1.4
    +v-xs
      font-size: 1.15em
      line-height: 1.25
  &-intro
    font-size: 1.65em
    font-weight: 500
    letter-spacing: -0.025em
    +v-sm
      zoom: .85
    +v-sm
      zoom: .85
    &-sub
      width: 90%
      font-size: 1.25em
      font-weight: 400
      line-height: 1.5
      +v-sm
        width: 100%
        margin: 16px auto

        img
          display: block !important
        span.px-3
          width: 100% !important
          padding-left: 0 !important
          padding-right: 0 !important
          text-align: center !important
    &-cta
      font-size: 2em
      font-weight: 700
  &-sub
    font-size: 1.65em
    font-weight: 500
    +v-xs
      font-size: .9em
      margin-left: 3%
      margin-right: 3%

.col-lg-8.pl-5
  padding-left: 0 !important
